import React from "react"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// core components
import GridContainer from "./../../../components/Grid/GridContainer.jsx"
import GridItem from "./../../../components/Grid/GridItem.jsx"

import productStyle from "./../../../assets/jss/material-kit-react/views/landingPageSections/productStyle.jsx"

class MapSection extends React.Component {
  render() {
    const { classes } = this.props
    return (
      <div id="map" className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <h3 className={classes.title}>Retrouvez nous</h3>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2932.2007546213486!2d2.931105915004655!3d42.69946977916553!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12b06f88a78c7437%3A0x85360984c8b1ae1a!2sDropAndClean!5e0!3m2!1sfr!2sfr!4v1579295656788!5m2!1sfr!2sfr"
              width="100%"
              height="450"
              frameborder="0"
              allowfullscreen=""
            />
          </GridItem>
        </GridContainer>
      </div>
    )
  }
}

export default withStyles(productStyle)(MapSection)
